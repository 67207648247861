@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'), url(../../public/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url(../../public/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

.PoppinsSemiBold {
  font-family: 'PoppinsSemiBold' !important;
}

.PoppinsRegular {
  font-family: "PoppinsRegular" !important;
}
